import { Box, Container } from "@material-ui/core";
import React, { useEffect } from "react";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import FaqRepository from "../repositories/FaqRepository";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    [theme.breakpoints.up("sm")]: {
      flexShrink: 0,
    },
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

function CharterPolicy() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [faqList, setFaqList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      setIsLoading(true);
      FaqRepository.getFaq().then((snapshot) => {
        const faqs = snapshot.docs.map((doc) => doc.data());
        // console.log(faqs);
        setTimeout(() => {
          setFaqList(faqs);
          setIsLoading(false);
        }, 500);
      });
    }

    return () => {
      unmounted = true;
    };
  }, [setFaqList]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box className={classes.root}>
      <Container>
        <h1>Politica de privacidade</h1>
        <p>Seja bem-vindo à nossa Política de Privacidade! A 4Bus está comprometida em proteger a sua privacidade.
         Esta política de privacidade (“Política de Privacidade” ou "Política"), em conjunto com os nossos Termos de Uso, explica como a 4Bus trata suas informações pessoais coletadas e utilizadas no contexto das atividades da 4Bus.
         Para fins desta Política de Privacidade, “Usuário” ou “Você” significa todo e qualquer indivíduo que faça uso ou tenha acesso ao site www.4Bus.com.br ou o aplicativo para dispositivos móveis (aqui referidos como “Plataformas”) e/ou aos serviços disponibilizados, distribuídos ou de qualquer forma fornecidos diretamente ou através da 4Bus, ou ainda, que participe de promoções, eventos ou pesquisas de opinião realizados pela 4Bus (“Serviços”).
        </p>
        <h3>Esta Política de Privacidade está dividida em 8 seções:</h3>
        <p></p>
        <p>1. Quais Dados Pessoais Coletamos?</p>
        <p>2. Como Coletamos Os Seus Dados Pessoais?</p>
        <p>3. Para Quais Finalidades Tratamos Os Seus Dados Pessoais?</p>
        <p>4. Com Quem Compartilhamos Os Seus Dados Pessoais?</p>
        <p>5. Como E Por Quanto Tempo Armazenamos Seus Dados Pessoais?</p>
        <p>6. Quais São Seus Direitos Como Titular De Dados Pessoais?</p>
        <p>7. Como Exercer Os Seus Direitos Como Titular De Dados Pessoais?</p>
        <p>8. Atualizações À Política De Privacidade</p>
        <p></p>
        <p>
        Esta Política de Privacidade não se aplica ao tratamento de dados pessoais realizado por outras empresas ou indivíduos, sites ou outros terceiros, incluindo eventuais parceiros da 4Bus. VOCÊ DEVERÁ CONSULTAR AS POLÍTICAS DE PRIVACIDADE DESSAS OUTRAS EMPRESAS PARA ENTENDER COMO ELAS TRATAM OS SEUS DADOS PESSOAIS. A 4Bus não será responsável pelo tratamento de dados pessoais nesses casos.
        Esta Política de Privacidade foi atualizada pela última vez em 25º.01.2023.
        </p>

        <h3>1. QUAIS DADOS PESSOAIS COLETAMOS?</h3>
        <p>Quando Você acessa e/ou utiliza os nossos Serviços, a 4Bus poderá realizar o tratamento de determinadas informações fornecidas, coletadas ou relacionadas a Você, tais como:</p>
        <p>• Dados de cadastro, como, por exemplo, nome, endereço, telefone, data de nascimento, gênero, e-mail, foto, RG, CPF, apelido, senha, para os Serviços que solicitarem cadastro;</p>
        <p>• Informações complementares de identificação quando Você se cadastra e utiliza os Serviços, registra uma ocorrência, participa de alguma pesquisa de satisfação ou de nossas ações promocionais (como, por exemplo, informações sobre a viagem realizada ou desconto recebido);</p>
        <p>• Dados financeiros e de pagamento, como, por exemplo, nome do banco, sigla BIN, bandeira do cartão de crédito, categoria do cartão de crédito, número de agência, número da conta e chave PIX;</p>
        <p>• Informações relacionadas ao seu consumo dos Serviços, como, por exemplo, número de viagens realizadas, data de e horário de acesso às Plataformas, trecho da viagem, número da reserva, valor pago;</p>
        <p>• Informações relacionadas aos seus interesses nos Serviços, como, por exemplo, buscas no site sobre a origem e destino;</p>
        <p>• Informações técnicas coletadas do dispositivo que Você utiliza para acessar nossas Plataformas como: Versão do SO, User Agent e Plataforma.</p>
        <p>• Informações que recebemos de terceiros sobre Você, inclusive de bancos de dados publicamente disponíveis, informações que Você voluntariamente disponibiliza para o público em geral (como, por exemplo, seu perfil, publicações ou informações em redes sociais);</p>
        <p>• Informações que geramos sobre Você a partir da utilização dos Serviços e dos demais dados obtidos; e</p>
        <p>• Sua imagem enviada por Você através das nossas Plataformas.</p>
        
        <p>Conforme a atividade promovida pela 4Bus, outros dados pessoais podem ser coletados, em formulário específico e para atender a esta finalidade. Essas informações podem ser utilizadas nas situações e para as finalidades descritas na Seção 3, abaixo.</p>
        <h4>Parágrafo Único:</h4>
        <p><b>Dados Sensíveis</b>. Conforme definição legal, dados pessoais sensíveis são aqueles relacionados: a origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico (“Dados Sensíveis”). A 4Bus não coleta Dados Sensíveis dos Usuários.</p>
        <p><b>Informações de Crianças</b>. Coletamos informações de crianças mediante o consentimento de seus pais ou de um responsável legal ou quando a coleta for necessária para a proteção da criança. Se Você for maior de 16 (dezesseis) anos, podemos coletar informações para atender a solicitações e fornecer os Serviços requisitados.</p>
        <p><b>Atualização</b>. Periodicamente, poderemos solicitar a atualização de seus dados de cadastro.</p>
        
        <h3>2. COMO COLETAMOS OS SEUS DADOS PESSOAIS?</h3>
        <p>• Quando da criação do seu cadastro em uma de nossas Plataformas, sendo que seu cadastro poderá ser associado a outras informações que coletamos ou que Você nos fornecer;</p>
        <p>• Quando Você acessa, solicita ou utiliza um dos Serviços da 4Bus, incluindo nossas Plataformas;</p>
        <p>• Quando Você acessa, solicita ou utiliza um dos Serviços da 4Bus, incluindo nossas Plataformas;</p>
        <p>• Quando trabalhamos com parceiros comerciais que possuem informações sobre Você, coletadas de maneira independente e para as finalidades descritas na Seção 3, abaixo.</p>
        <p><b>Ações Promocionais</b>. A 4Bus pode coletar dados pessoais relacionados a Você em ações de prospecção de relacionamento com os Usuários, a partir de ofertas de cupons de desconto, por exemplo. Nessas situações, os dados pessoais podem ser fornecidos diretamente por Você ou por meio de indicação de outro Usuário.</p>
        <p><b>Dados coletados online</b>.Conforme Você acessa uma de nossas Plataformas, informações técnicas relativas ao seu dispositivo ou conexão podem ser obtidas pela 4Bus, como tipo e marca do aparelho celular, identificadores de dispositivos móveis, versão do sistema operacional, informações sobre rede, configuração dos dispositivos e dados de software.
        Quando Você utiliza nossas Plataformas, também podemos utilizar cookies ou outras tecnologias similares para aprender como Você interage com o nosso conteúdo e para melhorar sua experiência ao visitar o nosso website. Essas tecnologias permitem análises estatísticas de uso de uma determinada aplicação, oferecimento ou desenvolvimento de funcionalidades, mas também podem permitir identificação de dispositivos, navegadores, informações técnicas, perfis de usuários, para personalizar conteúdos, incluindo de publicidade, enquanto Você acessa as Plataformas.
        Você pode gerenciar essas tecnologias por meio de configurações do seu dispositivo ou do navegador de internet. A configuração destas tecnologias, no entanto, pode afetar alguns recursos oferecidos pelas nossas plataformas online.</p>
        <p><b>Informações coletadas em bancos de dados de terceiros</b>. A 4Bus também poderá obter informações sobre Você provenientes de bancos de dados públicos e privados, como parceiros comerciais, desde que suas informações tenham sido obtidas em conformidade com a legislação aplicável e possam ser compartilhadas com a 4Bus.
        Essas informações poderão ser associadas às demais informações coletadas sobre ou fornecidas por Você e também utilizadas nas situações e para as finalidades descritas na Seção 3, abaixo. As informações fornecidas à 4Bus por parceiros comerciais ou quaisquer terceiros, obedecem à disciplina própria estabelecida por esses e poderão estar sujeitas às suas respectivas políticas de privacidade.</p>
        
        <h3>3. PARA QUAIS FINALIDADES TRATAMOS OS SEUS DADOS PESSOAIS?</h3>
        <p>Seus dados pessoais são utilizados para as seguintes finalidades:</p>
        <p>• disponibilizar, prestar, fornecer e manter, nossos Serviços;</p>
        <p>• promover a sua segurança, quando Você utiliza nossos Serviços e, inclusive, possibilitar o remanejamento de passageiros, quando necessário, como em casos de problemas mecânicos na frota, evitando infortúnios a Você;</p>
        <p>• para fins de registro e resolução de ocorrências, como pedido de reembolso ou devolução de item perdido;</p>
        <p>• para conhecermos melhor o seu perfil como cliente e disponibilizarmos promoções, ofertas, descontos e vantagens, de acordo com seus interesses e informações de compras;</p>
        <p>• para fins de publicidade e anúncios sobre os Serviços da 4Bus ou de terceiros, incluindo publicidade direcionada, no contexto de nossas Plataformas;</p>
        <p>• para permitir a prestação de serviços por terceiros que sejam parceiros comerciais da 4Bus;</p>
        <p>• para nos comunicarmos com Você, inclusive através de anúncios de serviço, divulgação de eventos, notícias, informativos, notificações, SMS, mensagens via aplicativos como WhatsApp ou marketing relacionado às nossas Plataformas;</p>
        <p>• para realizar atendimentos e cumprir com solicitações realizadas por Você em relação aos nossos Serviços;</p>
        <p>• para realizar ou apoiar promoções comerciais ou sorteios, quando Você optar por participar de tais atividades;</p>
        <p>• para gerar análises e relatórios estatísticos sobre a performance e operação dos nossos Serviços e Plataformas, em benefício da 4Bus e dos seus parceiros comerciais;</p>
        <p>• personalizar, desenvolver e aprimorar nossas Plataformas e funcionalidades, combinando e usando das informações e perfil que temos sobre Você, como, por exemplo, a maneira que Você interage com as Plataformas, os recursos que Você mais utiliza, os conteúdos com os quais Você mais interage e suas preferências de maneira geral;</p>
        <p>• realizar pesquisas de satisfação sobre nossos Serviços ou de parceiros;</p>
        <p>• para detectar, identificar, impedir e se defender de atividades fraudulentas, abusivas ou ilegais;</p>
        <p>• cumprir com obrigações legais e regulatórias de coleta, retenção e/ou compartilhamento de dados, incluindo com autoridades governamentais, na forma da legislação e regulamentação aplicáveis;</p>
        <p>• para o exercício de direitos da 4Bus em processos administrativos, arbitragem ou judiciais, na forma da lei, e para a defesa da 4Bus, seus direitos, bens e interesses legítimos.</p>
        

        <h3>4. COM QUEM COMPARTILHAMOS OS SEUS DADOS PESSOAIS?</h3>
        <p>A 4Bus pode compartilhar informações suas:</p>
        <p>• com parceiros comerciais, para cumprimento das finalidades previstas nesta Política de Privacidade ou atividades de suporte, como por exemplo, empresas de transporte coletivo na modalidade fretamento, sendo que tais parceiros comerciais poderão ser controladores dos seus dados pessoais na medida em que estabeleçam uma relação direta com Você;</p>
        <p>• para prestadores de serviço da 4Bus que atuem em nome da 4Bus e para o benefício dela na realização das finalidades previstas nesta Política de Privacidade, sendo que tais prestadores de serviço não terão um direito independente de utilizar suas informações;</p>
        <p>• quando Você requerer ou consentir com o compartilhamento;</p>
        <p>• a órgãos, agências, autoridades e demais entes da administração e/ou do poder público, bem como a pessoas físicas ou jurídicas de natureza privada, em cumprimento de obrigação legal ou de ordem judicial;</p>
        <p>• para o exercício e defesa de quaisquer direitos da 4Bus, ao seu critério, incluindo no âmbito de processos judiciais ou administrativos;</p>
        <p>• em caso de alteração da propriedade ou controle da 4Bus, em razão de fusão, aquisição, cisão ou venda de ativos, sendo que suas informações poderão ser transferidas a um novo proprietário.</p>
        <p>A 4Bus não vende ou oferta os dados pessoais dos Usuários para empresas, organizações ou outros indivíduos fora da 4Bus.</p>
        <p><b>Transferência Internacional de Dados Pessoais</b>. A 4Bus possui parceiros comerciais e prestadores de serviço no Brasil e no exterior e, nesse sentido, utiliza uma infraestrutura técnica em escala global, com o objetivo de otimizar a eficiência, a performance e a segurança das Plataformas.
        Por isso, seus dados pessoais poderão ser transferidos para outros países, para tratamento e armazenamento de dados, de acordo com os termos e as finalidades definidas em nossa Política de Privacidade, bem como para o fornecimento dos Serviços solicitados por Você.
        4Bus PODERÁ TRATAR SEUS DADOS PESSOAIS, INCLUINDO O ARMAZENAMENTO, NO BRASIL OU NO EXTERIOR. EM QUALQUER CASO, A 4Bus IRÁ ADOTAR AS PROVIDÊNCIAS E SALVAGUARDAS NECESSÁRIAS, TÉCNICAS E LEGAIS, PARA GARANTIR A OBSERVÂNCIA DESSA POLÍTICA DE PRIVACIDADE E OS SEUS DIREITOS RELACIONADOS À PROTEÇÃO DE DADOS PESSOAIS.</p>
        
        <h3>5. COMO E POR QUANTO TEMPO ARMAZENAMOS SEUS DADOS PESSOAIS?</h3>
        <p><b>Armazenamento</b>.As informações coletadas pela 4Bus podem ser armazenadas diretamente pela 4Bus e/ou por prestadores de serviço da 4Bus. A 4Bus adotará padrões de segurança para a proteção da integridade, confidencialidade e disponibilidade das informações armazenadas conforme disposto abaixo.</p>
        <p><b>Por Quanto Tempo Mantemos Suas Informações? </b>Suas informações são tratadas e mantidas pelo período necessário para fornecer os Serviços, enquanto Você permanecer utilizando ou vinculado a um de nossos Serviços; para cumprir com as finalidades determinadas na Seção 3; e para lidar com quaisquer demandas, bem como para cumprir os requisitos legais, contábeis ou regulatórios aplicáveis.</p>
        <p><b>Segurança</b>.A 4Bus se responsabiliza pela adoção e manutenção de medidas razoáveis de segurança, técnicas e administrativas para prevenção de tratamento irregular ou ilícito dos dados pessoais dos Usuários, inclusive hipóteses não autorizadas de acesso, destruição, perda, alteração ou comunicação dos dados. Essas medidas incluem proteções físicas, operacionais e tecnológicas, incluindo o controle de acesso às informações, a utilização de softwares de segurança e o treinamento de nossos colaboradores.
        As medidas de segurança aplicam-se às suas informações somente a partir do momento em que a 4Bus as recebe e enquanto as mantém sob sua guarda. O funcionamento e a segurança do dispositivo que Você usa para acessar nossas Plataformas, bem como redes terceiras pelas quais os dados trafegam não são de responsabilidade da 4Bus.</p>
        <p><b>Links para sites de terceiros. </b>. Os Serviços, anúncios e outras comunicações podem, periodicamente, conter links para e de sites de terceiros. Os dados pessoais que Você fornece nesses sites não estão sujeitos a esta Política de Privacidade, assim como o tratamento de seus dados pessoais por tais sites não é de nossa responsabilidade.</p>


        <h3>6. QUAIS SÃO SEUS DIREITOS COMO TITULAR DE DADOS PESSOAIS?</h3>
        <p>• confirmação da existência de tratamento dos dados pessoais;</p>
        <p>• acesso aos dados pessoais;</p>
        <p>• correção de dados pessoais incompletos, inexatos ou desatualizados;</p>
        <p>• anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na LGPD;</p>
        <p>• portabilidade dos dados pessoais para outro fornecedor de um serviço ou produto, sujeito à regulamentação da autoridade nacional;</p>
        <p>• eliminação de dados pessoais tratados com base no seu consentimento, exceto quando a lei autorizar a manutenção destes dados por outro fundamento;</p>
        <p>• informações sobre as entidades públicas e privadas com as quais a 4Bus tenha realizado o uso compartilhado dos seus dados pessoais;</p>
        <p>• informações sobre a possibilidade de não dar consentimento ao tratamento dos seus dados pessoais e sobre as consequências de tal ação; e</p>
        <p>• revogação do seu consentimento, quando o tratamento tenha sido </p>

        <h3>7. COMO EXERCER OS SEUS DIREITOS COMO TITULAR DE DADOS PESSOAIS?</h3>
        <p>Caso Você queira exercer algum desses direitos ou deseje obter maiores esclarecimentos sobre a aplicação dessa Política de Privacidade, entre em contato conosco, através do Encarregado de Dados da 4Bus, utilizando o endereço eletrônico contato@4bus.com.br.
         Teremos a satisfação de esclarecer-lhe eventuais dúvidas e/ou atender à sua solicitação. Observe que nos reservamos o direito de verificar sua identidade antes de respondermos à sua solicitação.
         A 4Bus PODERÁ MANTER DETERMINADOS DADOS PESSOAIS SEUS ARMAZENADOS APÓS O SEU PEDIDO DE EXCLUSÃO, CONFORME E NA MEDIDA DO AUTORIZADO PELA LEGISLAÇÃO APLICÁVEL.
         CASO VOCÊ SOLICITE A EXCLUSÃO DAS SUAS INFORMAÇÕES, MAS AINDA TENHA ALGUMA OBRIGAÇÃO PENDENTE DE CUMPRIMENTO COM A 4Bus, AS SUAS INFORMAÇÕES NÃO SERÃO EXCLUÍDAS E PERMANECERÃO ARMAZENADAS PARA O FIM DE VIABILIZAR A SOLUÇÃO DA PENDÊNCIA E A ADOÇÃO DE MEDIDAS CABÍVEIS.</p>

        <h3>8. ATUALIZAÇÕES À POLÍTICA DE PRIVACIDADE</h3>
        <p>A presente Política poderá ser atualizada periodicamente.
         Todas as alterações deverão ser consideradas como de aplicação e vigência imediata, exceto em caso de comunicação em contrário enviada pela 4Bus aos titulares de dados.
         A 4Bus tomará medidas razoáveis para comunicar os Usuários acerca das atualizações nos termos desta Política de Privacidade.</p>
       
         <h4>Contato:</h4>              
            <p> E-mail: contato@4bus.com.br</p>
            <p> Suporte via chat no botão Ajuda ou através do App </p>
      </Container>
    </Box>
  );
}

export default CharterPolicy;
