import {
  AUTH_SIGN_IN_CLOSE_MODAL,
  AUTH_SIGN_IN_EMAIL_PASSWORD,
  AUTH_SIGN_IN_ERROR,
  AUTH_SIGN_IN_OPEN_MODAL,
  AUTH_SIGN_IN_SUCCESS,
  AUTH_SIGN_OUT,
  AUTH_SIGN_UP_CLOSE_MODAL,
  AUTH_SIGN_UP_ERROR,
  CLOSE_SNACKBAR,
  ENQUEUE_SNACKBAR,
  FEATURED_TRIPS_ERROR,
  FEATURED_TRIPS_SUCCESS,
  GET_FEATURED_TRIPS,
  GET_FOR_SALE_TRIPS,
  FOR_SALE_TRIPS_ERROR,
  FOR_SALE_TRIPS_SUCCESS,
  GET_USER_LAST_SEARCHES,
  REMOVE_SNACKBAR,
  SEARCH_TRIPS,
  SEARCH_TRIPS_GOING_ERROR,
  SEARCH_TRIPS_GOING_SUCCESS,
  SEARCH_PARTNER_TRIPS_GOING_SUCCESS,
  SEARCH_PARTNER_TRIPS_GOING_ERROR,
  SEARCH_TRIPS_RETURN_ERROR,
  SEARCH_TRIPS_RETURN_SUCCESS,
  SEARCH_PARTNER_TRIPS_RETURN_SUCCESS,
  SEARCH_PARTNER_TRIPS_RETURN_ERROR,
  USER_LAST_SEARCHES_ERROR,
  USER_LAST_SEARCHES_SUCCESS,
} from './actionTypes';

import AuthApiService from '../services/AuthApiService';
import AuthRepository from '../repositories/AuthRepository';
import ERRORS from '../constants/errors';
import STORAGE from '../constants/storage';
import TripRepository from '../repositories/TripRepository';
import history from '../history';
import { default as auth } from './authActions';
import { default as checkout } from './checkoutActions';
import { default as customer } from './customerActions';
import { getStorage } from '../utils/storage';

export const enqueueSnackbar = (notification) => {
  const key = notification.options && notification.options.key;
  notification.options.style = { zIndex: 110000 };
  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random(),
    },
  };
};

export const closeSnackbar = (key) => ({
  type: CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
});

export const removeSnackbar = (key) => ({
  type: REMOVE_SNACKBAR,
  key,
});

export const getForSaleTrips = (trips) => ({
  type: GET_FOR_SALE_TRIPS
});

export const forSaleTripsSuccess = (trips) => ({
  type: FOR_SALE_TRIPS_SUCCESS,
  trips,
});

export const forSaleTripsError = (trips) => ({
  type: FOR_SALE_TRIPS_ERROR,
  trips,
});

export const searchForSaleTrips = () => {
  return dispatch => {
    dispatch(getForSaleTrips())

    const tripRepository = new TripRepository()

    const onSuccessCallback = (data) => dispatch(forSaleTripsSuccess(data))
    const onErrorCallback = () => dispatch(forSaleTripsError())

    tripRepository.getForSaleTrips(onSuccessCallback, onErrorCallback)
  }
}
export const searchTrips = (
  cityDeparture,
  cityArrival,
  departDate,
  returnDate,
  saveHistory = true,
  userUID = null,
  onFinish,
  detail = false
) => {
  return (dispatch) => {
    const isRoundTrip = !!returnDate;
    dispatch(
      searchTripsSaveParams(
        isRoundTrip,
        cityDeparture,
        cityArrival,
        new Date(departDate),
        returnDate ? new Date(returnDate) : null,
        detail
      )
    );

    const tripRepository = new TripRepository();

    //#region acionaTotalBus
    // tripRepository
    //   .searchTripTotalbus(
    //     cityDeparture && cityDeparture.id,
    //     cityArrival && cityArrival.id,
    //     departDate
    //   )
    //   .then(
    //     (data) => {
    //       setTimeout(() => {
    //         if (data.status === 200) {
    //           // dispatch(searchPartnerTripsGoingSuccess(data));
    //         }
    //       }, 250);
    //     },
    //     (error) => {
    //       setTimeout(() => {
    //         // dispatch(searchPartnerTripsGoingError(error));
    //       }, 250);
    //     }
    //   )
    //   .finally(() => Boolean(onFinish) && onFinish());

    // if (isRoundTrip) {
    //   tripRepository
    //     .searchTripTotalbus(
    //       cityArrival && cityArrival.id,
    //       cityDeparture && cityDeparture.id,
    //       returnDate
    //     )
    //     .then((data) => {
    //       setTimeout(() => {
    //         if (data.status === 200) {
    //           // dispatch(searchPartnerTripsReturnSuccess(data));
    //         }
    //       }, 250);
    //     })
    //     .catch((error) => {
    //       setTimeout(() => {
    //         // dispatch(searchPartnerTripsReturnError(error));
    //       }, 250);
    //     })
    //     .finally(() => onFinish && onFinish());
    // }

    const callbackSucessGoing = (data) => {
      if (saveHistory && userUID) {
        tripRepository.saveUserLastSearch(
          userUID,
          cityDeparture,
          cityArrival,
          departDate,
          returnDate
        );
        dispatch(getUserLastSearches(userUID));
      }

      const userDetails = JSON.parse(localStorage.getItem(STORAGE.USER));

      tripRepository.saveUserLastSearchLog(
        userDetails !== null ? userDetails.userName : null,
        userDetails !== null ? userDetails.id : null,
        cityDeparture,
        cityArrival,
        departDate,
        returnDate
      );

      setTimeout(() => {
        dispatch(searchTripsGoingSuccess(data));
      }, 250);
     
    };

    const callbackSucessReturn = (data) => {
      setTimeout(() => {
        dispatch(searchTripsReturnSuccess(data));
      }, 250);
    };
    const callbackError = (error) => {

    };

    const callbackCompletion = () => {

    };

    tripRepository.searchTrip(
      cityDeparture && cityDeparture.id,
      cityArrival && cityArrival.id,
      departDate,
      callbackSucessGoing
    );

    if (isRoundTrip) {
      tripRepository.searchTrip(
        cityArrival && cityArrival.id,
        cityDeparture && cityDeparture.id,
        returnDate,
        callbackSucessReturn
      );
    }
  };
};

const searchTripsSaveParams = (
  isRoundTrip,
  cityDeparture,
  cityArrival,
  departDate,
  returnDate,
  detail
) => ({
  type: SEARCH_TRIPS,
  isRoundTrip,
  cityDeparture,
  cityArrival,
  departDate,
  returnDate,
  detail,
});

export const searchTripsGoingSuccess = (trips) => ({
  type: SEARCH_TRIPS_GOING_SUCCESS,
  trips,
});

export const searchTripsGoingError = (error) => ({
  type: SEARCH_TRIPS_GOING_ERROR,
  error,
});

export const searchPartnerTripsGoingSuccess = (trips) => ({
  type: SEARCH_PARTNER_TRIPS_GOING_SUCCESS,
  trips,
});

export const searchPartnerTripsGoingError = (error) => ({
  type: SEARCH_PARTNER_TRIPS_GOING_ERROR,
  error,
});

export const searchTripsReturnSuccess = (trips) => ({
  type: SEARCH_TRIPS_RETURN_SUCCESS,
  trips,
});

export const searchTripsReturnError = (error) => ({
  type: SEARCH_TRIPS_RETURN_ERROR,
  error,
});

export const searchPartnerTripsReturnSuccess = (trips) => ({
  type: SEARCH_PARTNER_TRIPS_RETURN_SUCCESS,
  trips,
});

export const searchPartnerTripsReturnError = (error) => ({
  type: SEARCH_PARTNER_TRIPS_RETURN_ERROR,
  error,
});

export const getFeaturedTrips = () => {
  const tripRepository = new TripRepository();
  return (dispatch) => {
    dispatch(internalGetFeaturedTrips());
    Promise.all([
      tripRepository.getFeaturedTrips(2),
      tripRepository.getFeaturedTrips(3),
      tripRepository.getFeaturedTrips(14),
    ])
      .then(([status2, status3, status14]) => {
        const dataStatus2 = status2.docs.map((i) => i.data());
        const dataStatus3 = status3.docs.map((i) => i.data());
        const dataStatus14 = status14.docs.map((i) => i.data());

        const data = [...dataStatus2, ...dataStatus3, ...dataStatus14];

        setTimeout(() => {
          dispatch(featuredTripsSuccess(data));
        }, 150);
      })
      .catch((reason) => featuredTripsError(reason));
  };
};

const internalGetFeaturedTrips = () => ({
  type: GET_FEATURED_TRIPS,
});

export const featuredTripsSuccess = (trips) => ({
  type: FEATURED_TRIPS_SUCCESS,
  trips,
});

export const featuredTripsError = (error) => ({
  type: FEATURED_TRIPS_ERROR,
  error,
});

export const getUserLastSearches = (userId) => {
  const tripRepository = new TripRepository();
  return (dispatch) => {
    dispatch(internalGetUserLastSearches());
    tripRepository
      .getUserLastSearches(userId)
      .then((res) => {
        const data = res.docs.map((i) => i.data());

        setTimeout(() => {
          dispatch(getUserLastSearchesSuccess(data));
        }, 150);
      })
      .catch((reason) => getUserLastSearchesError(reason));
  };
};

const internalGetUserLastSearches = () => ({
  type: GET_USER_LAST_SEARCHES,
});

export const getUserLastSearchesSuccess = (trips) => ({
  type: USER_LAST_SEARCHES_SUCCESS,
  trips,
});

export const getUserLastSearchesError = (error) => ({
  type: USER_LAST_SEARCHES_ERROR,
  error,
});

function handleSignInSuccess(user, accessToken, dispatch) {
  // console.log("user", user);
  if (user.type === 2 || user.type === 5) {
    localStorage.setItem(STORAGE.REMEMBER_LOGIN, true);

    const newStorage = getStorage(true);

    newStorage.setItem(STORAGE.USER, JSON.stringify(user));
    newStorage.setItem(STORAGE.ACCESS_TOKEN, accessToken);

    setTimeout(() => {
      dispatch(authSignInCloseModal());
      dispatch(authSignInSuccess(user));
    }, 150);
  } else {
    dispatch(authSignInError({ code: ERRORS.INVALID_USER_TYPE }));
  }
}

export const authSignInEmailPassword = (email, password, rememberMe) => {
  const authRepository = new AuthRepository();

  return (dispatch) => {
    dispatch(internalAuthSignInEmailPassword());

    authRepository
      .signInEmailPassword(email, password, rememberMe)
      .then((authRes) => {
        // const data = authRes;
        // console.log("data", data);

        authRes.user.getIdToken().then((accessToken) => {
          AuthApiService.getUser(accessToken).then((res) => {
            // console.log("res", res);

            handleSignInSuccess(res.data, accessToken, dispatch);
          });
        });
      })
      .catch((reason) => {
        setTimeout(() => {
          dispatch(authSignInError(reason));
        }, 0);
      });
  };
};

export const authSignInGoogle = () => {
  const authRepository = new AuthRepository();

  return (dispatch) => {
    authRepository
      .signInGoogle()
      .then((data) => {
        // console.log("data", data);

        data.user.getIdToken().then((accessToken) => {
          AuthApiService.getUser(accessToken).then(
            (res) => {
              if (res.status === 200) {
                // console.log("authSignInGoogle user", user);
                handleSignInSuccess(res.data, accessToken, dispatch);
              }
            },
            (reason) => {
              // console.log("reason", reason);
              if (reason.status === 401) {
                // dispatch(authSignUp(data.user.displayName, data.user.email));

                AuthApiService.createAccount(
                  data.user.email,
                  data.user.displayName,
                  data.user.uid
                ).then((res) => {
                  // console.log("axios post res", res);
                  if (res.status === 200) {
                    dispatch(
                      enqueueSnackbar({
                        message: 'Cadastro realizado com sucesso!',
                        options: {
                          key: 'signUpSnack',
                          autoHideDuration: 3000,
                          variant: 'success',
                          anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                          },
                        },
                      })
                    );

                    handleSignInSuccess(res.data, accessToken, dispatch);
                  } else {
                    throw new Error('Erro desconhecido');
                  }
                });
              }
            }
          );
        });
      })
      .catch((reason) => {
        console.log("reason", reason);
        setTimeout(() => {
          dispatch(authSignInError(reason));
        }, 0);
      });
  };
};

const internalAuthSignInEmailPassword = () => ({
  type: AUTH_SIGN_IN_EMAIL_PASSWORD,
});

export const authSignInOpenModal = () => ({
  type: AUTH_SIGN_IN_OPEN_MODAL,
});

export const authSignInCloseModal = () => ({
  type: AUTH_SIGN_IN_CLOSE_MODAL,
});

export const authSignUpCloseModal = () => ({
  type: AUTH_SIGN_UP_CLOSE_MODAL,
});

export const authSignInSuccess = (user) => ({
  type: AUTH_SIGN_IN_SUCCESS,
  user,
});

export const authSignInError = (error) => ({
  type: AUTH_SIGN_IN_ERROR,
  error,
});

export const authSignOut = () => {
  const authRepository = new AuthRepository();
  return (dispatch) => {
    authRepository.signOut().then(() => {
      history.push('/');
      dispatch(internalAuthSignOut());
    });
  };
};

const internalAuthSignOut = () => ({
  type: AUTH_SIGN_OUT,
});

export const authSignUp = (name, email, password, cellphone = '', onFinish) => {
  const authRepository = new AuthRepository();

  return (dispatch) => {
    authRepository
      .signUp(name, email, password)
      .then((repositoryResponse) => {

        AuthApiService.createAccount(
          email,
          name,
          repositoryResponse.user.uid,
          cellphone
        )
          .then(
            (res) => {
              if (res.status === 200) {
                dispatch(
                  enqueueSnackbar({
                    message: 'Cadastro realizado com sucesso!',
                    options: {
                      key: 'signUpSnack',
                      autoHideDuration: 3000,
                      variant: 'success',
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                      },
                    },
                  })
                );

                repositoryResponse.user
                  .getIdToken()
                  .then((accessToken) =>
                    handleSignInSuccess(res.data, accessToken, dispatch)
                  );
              }
            },
            (error) => {
              dispatch(authSignUpError(error.message));
            }
          )
          .finally(() => {
            onFinish && onFinish();
          });
      })
      .catch((reason) => {
        dispatch(authSignUpError(reason));
      })
      .finally(() => {
        onFinish && onFinish();
      });
  };
};

const authSignUpError = (error) => ({
  type: AUTH_SIGN_UP_ERROR,
  error,
});

const Actions = { auth, customer, checkout };

export default Actions;
