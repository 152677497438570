export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

// export const SET_BOOKING = "SET_BOOKING"
export const SEARCH_TRIPS = 'SEARCH_TRIPS';
export const SEARCH_TRIPS_GOING_SUCCESS = 'SEARCH_TRIPS_GOING_SUCCESS';

export const SEARCH_TRIPS_GOING_ERROR = 'SEARCH_TRIPS_GOING_ERROR';
export const SEARCH_PARTNER_TRIPS_GOING_SUCCESS =
  'SEARCH_PARTNER_TRIPS_GOING_SUCCESS';
export const SEARCH_PARTNER_TRIPS_GOING_ERROR =
  'SEARCH_PARTNER_TRIPS_GOING_ERROR';
export const SEARCH_PARTNER_TRIPS_RETURN_SUCCESS =
  'SEARCH_PARTNER_TRIPS_RETURN_SUCCESS';
export const SEARCH_PARTNER_TRIPS_RETURN_ERROR =
  'SEARCH_PARTNER_TRIPS_RETURN_ERROR';
export const SEARCH_TRIPS_RETURN_SUCCESS = 'SEARCH_TRIPS_RETURN_SUCCESS';
export const SEARCH_TRIPS_RETURN_ERROR = 'SEARCH_TRIPS_RETURN_ERROR';

export const GET_FEATURED_TRIPS = 'GET_FEATURED_TRIPS';
export const FEATURED_TRIPS_SUCCESS = 'FEATURED_TRIPS_SUCCESS';
export const FEATURED_TRIPS_ERROR = 'FEATURED_TRIPS_ERROR';

export const USER_LAST_SEARCHES_SUCCESS = 'USER_LAST_SEARCHES_SUCCESS';
export const USER_LAST_SEARCHES_ERROR = 'USER_LAST_SEARCHES_ERROR';
export const GET_USER_LAST_SEARCHES = 'GET_USER_LAST_SEARCHES';
export const SAVE_USER_SEARCH = 'SAVE_USER_SEARCH';

export const AUTH_SIGN_IN_OPEN_MODAL = 'AUTH_SIGN_IN_OPEN_MODAL';
export const AUTH_SIGN_IN_CLOSE_MODAL = 'AUTH_SIGN_IN_CLOSE_MODAL';
export const AUTH_SIGN_IN_EMAIL_PASSWORD = 'AUTH_SIGN_IN_EMAIL_PASSWORD';
export const AUTH_SIGN_IN_GOOGLE = 'AUTH_SIGN_IN_GOOGLE';
export const AUTH_SIGN_IN_SUCCESS = 'AUTH_SIGN_IN_SUCCESS';
export const AUTH_SIGN_IN_ERROR = 'AUTH_SIGN_IN_ERROR';
export const AUTH_SIGN_OUT = 'AUTH_SIGN_OUT';

export const AUTH_SIGN_UP = 'AUTH_SIGN_UP';
export const AUTH_SIGN_UP_ERROR = 'AUTH_SIGN_UP_ERROR';
export const AUTH_SIGN_UP_CLOSE_MODAL = 'AUTH_SIGN_UP_CLOSE_MODAL';

export const GET_FOR_SALE_TRIPS = 'GET_FOR_SALE_TRIPS';
export const FOR_SALE_TRIPS_SUCCESS = 'FOR_SALE_TRIPS_SUCCESS';
export const FOR_SALE_TRIPS_ERROR = 'FOR_SALE_TRIPS_ERROR';