import React, { useEffect } from 'react';
import TripCardsContainer from '../../../trip-cards-container/TripCardsContainer';
import { Typography } from '@material-ui/core';

export function TabPanel(props) {
  const {
    value,
    index,
    trips,
    tripsPartner,
    cityDepartureName,
    cityArrivalName,
    isLoading,
    isLoadingPartner,
    onSelectTrip,
    firstTrip,
    settings,
    ...other
  } = props;


  const [tripsEdit, setTripsEdit] = React.useState([]);
  const [partnerTripsEdit, setPartnerTripsEdit] = React.useState([]);
  // console.log("trips", trips, user);
  useEffect(() => {
    setTripsEdit(trips);
    setPartnerTripsEdit(tripsPartner);


    if (firstTrip && settings) {
      const tripsEdited = trips.map((trip) => {
        settings.map((discount) => {
          if (trip.etd >= discount.startDate && trip.etd <= discount.endDate) {
            if (discount.typeValue === 'V') {
              trip.ticketFirstTrip = trip.ticketValue - discount.value;
            } else {
              trip.ticketFirstTrip =
                trip.ticketValue -
                parseFloat(trip.ticketValue * (discount.value / 100)).toFixed(
                  2
                );
            }
          }
        });
        return trip;
      });
      setTripsEdit(tripsEdited);
    }
  }, [trips, firstTrip, settings, tripsPartner]);
  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      <TripCardsContainer
        title='Resultado da Busca'
        subtitle={`${cityDepartureName} > ${cityArrivalName}`}
        skeletonCount='3'
        firstTrip={firstTrip}
        settings={settings}
        trips={tripsEdit}
        partnerTrips={partnerTripsEdit}
        isLoading={isLoading}
        isLoadingPartner={isLoadingPartner}
        onSelectTrip={onSelectTrip}
        isGoing={value === 'going' ? true : false}
      />
    </Typography>
  );
}
