import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography
} from '@material-ui/core';
import React, { useEffect } from 'react';

import AppCurrencyFormat from '../../shared/app-currency-format/AppCurrencyFormat';
import BookingApiService from '../../../services/BookingApiService';
import CustomerApiService from '../../../services/CustomerApiService';
import MoneyOff from '@material-ui/icons/MoneyOff';
import SettingsRepository from '../../../repositories/SettingsRepository';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { enqueueSnackbar } from '../../../actions';
import TripApiService from '../../../services/TripApiService';
import AuthApiService from '../../../services/AuthApiService';

function CheckoutSummary(props) {
  const { enqueueSnackbar } = props;
  const [coupon, setCoupon] = React.useState('');
  const [couponApplicable, setCouponApplicable] = React.useState(null);
  const [userWalletValue, setUserWalletValue] = React.useState(0);
  const [userWalletDiscount, setUserWalletDiscount] = React.useState(0);
  const [systemSettings, setSystemSettings] = React.useState({});
  const [couponButtonDisabled, setCouponButtonDisabled] = React.useState(false);
  const [discountValue, setDiscountValue] = React.useState(0);

  const departTrip = JSON.parse(sessionStorage.getItem('departTrip') || '{}');
  const returnTrip = JSON.parse(sessionStorage.getItem('returnTrip') || '{}');

  const selectedPassengers = JSON.parse(
    sessionStorage.getItem('selectedPassengers') || []
  );
  const clientBySeller = JSON.parse(
    localStorage.getItem("USER")
  );
  useEffect(() => {
    sessionStorage.removeItem('COUPON');
  }, []);

  const handleSetCoupon = event => {
    setCoupon(event.target.value);
  };
  let totalValue =
    departTrip.ticketValue * selectedPassengers.length -
    ((couponApplicable && couponApplicable.value) || 0) -
    (userWalletDiscount || 0) -
    ((!couponApplicable && discountValue) || 0);

    if (returnTrip && returnTrip.id) {
    // console.log(
    //   'totalValue, returnTrip.ticketValue',
    //   totalValue,
    //   returnTrip.ticketValue,
    // );
    totalValue += returnTrip.ticketValue * selectedPassengers.length;
    // totalValue += returnTrip.ticketValue;
  }
  const handleUseCredits = () => {
    let sub =
      departTrip.ticketValue * selectedPassengers.length -
      ((couponApplicable && couponApplicable.value) || 0) -
      ((!couponApplicable && discountValue) || 0);

    if (returnTrip && returnTrip.id) {
      sub += returnTrip.ticketValue * selectedPassengers.length;
    }
    if (!userWalletDiscount) {
      if (userWalletValue > systemSettings.maxValueUseCredits) {
        setUserWalletDiscount(systemSettings.maxValueUseCredits);
      } else {
        if (userWalletValue >= sub) {
          
          if (couponApplicable !== null) {
            setCouponApplicable(couponApplicable);
          }
          else
          {
            setCouponApplicable(null);
          }

          setUserWalletDiscount(sub);
        } else {
          setUserWalletDiscount(userWalletValue);
        }
      }
      sessionStorage.setItem('USE_CREDITS', JSON.stringify(true));
    } else {
      setUserWalletDiscount(0);
      sessionStorage.setItem('USE_CREDITS', JSON.stringify(false));
    }
  };

  const handleValidateCoupon = () => {
    if (coupon) {
    BookingApiService.validateCoupon(coupon, departTrip.id, returnTrip.id || 0)
      .then(
        res => {
          console.log('res valid cupon', res.data);
          setCouponButtonDisabled(true);
          if (res.data.valid) {
            let resPercent = 0;
            if (res.data.coupon.percentage) {
              let totalValueNoCup =
                departTrip.ticketValue * selectedPassengers.length;
              if (returnTrip && returnTrip.id) {
                totalValueNoCup += returnTrip.ticketValue;
              }
              resPercent = {
                ...res.data.coupon,
                value: totalValueNoCup * (res.data.coupon.percentage / 100)
              };
              setCouponApplicable(resPercent);
            } else {
              setCouponApplicable(res.data.coupon);
              console.log('RES PERCENTE2', res.data.coupon);
            }
            if (userWalletDiscount>res.data.coupon.value)
            {
              setUserWalletDiscount(userWalletDiscount - res.data.coupon.value);
            }
            else {
              setUserWalletDiscount(0);
            }

            enqueueSnackbar({
              message: 'Cupom aplicado',
              options: {
                key: 'couponSnack',
                autoHideDuration: 3000,
                variant: 'success',
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'center'
                }
              }
            });

            sessionStorage.setItem('COUPON', JSON.stringify(res.data.coupon));
          } else {
            sessionStorage.removeItem('COUPON');
            console.log("PEGOU CUPOM INVALIDO")
            enqueueSnackbar({
              message: 'Cupom inválido',
              options: {
                key: 'couponSnack',
                autoHideDuration: 3000,
                variant: 'error',
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'center'
                }
              }
            });
          }
        },
        error => {
          // console.log('ERROR ', error );
          enqueueSnackbar({
            message: error.data.erro || 'Erro ao validar o cupom',
            options: {
              key: 'couponSnack',
              autoHideDuration: 3000,
              variant: 'error',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              }
            }
          });
        }
      )
      .finally(() => {
        setTimeout(() => {
          //setCouponButtonDisabled(false);
        }, 250);
      });
    }
    else
    {
      enqueueSnackbar({
        message: 'Preencha um cupom',
        options: {
          key: 'couponSnack',
          autoHideDuration: 3000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          }
        }
      });
    }
  };
  const [firstTrip, setFirstTrip] = React.useState(false);
  const generateDiscountValue = () => {
    let userIdCustomer = clientBySeller.id
    console.log("generateDiscountValue",userIdCustomer)
    AuthApiService.getFullUser()
      .then(res => {
        console.log('generateDiscountValue', res.data);
        if (res.data.firstTrip) {
          TripApiService.getFirstTripDiscount(
            departTrip.id,
            returnTrip.id || 0,
            selectedPassengers.length,
            userIdCustomer
          ).then(e => {
            console.log('DISCOUNT', e.data);
            if (e.data !== '')
            {
              setDiscountValue(e.data.discountValue);
            }
            else
            {
              setFirstTrip(false);
            }
          }).catch(setFirstTrip(false));
        } else {
          setFirstTrip(false);
        }
      })
      .catch(err => {
        console.log('err ', err);
      });
  };
  useEffect(() => {
    generateDiscountValue();
    let unmounted = false;

    if (!unmounted) {
      const getCustomer = async () => {
        const res = await CustomerApiService.getCustomer();

        const systemSettings = JSON.parse(
          sessionStorage.getItem('SYSTEM_SETTINGS') || '{}'
        );

        try {
          const walletValue =
            res &&
            res.data &&
            res.data.user &&
            res.data.user.walletValue > systemSettings.maxValueUseCredits
              ? systemSettings.maxValueUseCredits
              : res.data.user.walletValue;
          setUserWalletValue(walletValue || 0);
        } catch (e) {
          console.log('error checkout -> ' + e);
        }
      };
      const getSettings = async () => {
        const snapshot = await SettingsRepository.getSettings();
        console.log('GETSETTINGS CHECKOUT', snapshot.data());
        //

        sessionStorage.setItem(
          'SYSTEM_SETTINGS',
          JSON.stringify(snapshot.data())
        );

        setSystemSettings(snapshot.data());
        getCustomer();
      };

      getSettings();
    }

    //

    return () => {
      //
      unmounted = true;
    };
  }, [setUserWalletValue, setSystemSettings, props]);
  console.log('summary -------->', props);
  console.log('summary -------->', discountValue);

  return (
    <Box component={Paper} padding={2}>
      <Box marginBottom={2} display="flex" justifyContent="space-between">
        <Grid container spacing={1} justify="flex-end" alignItems="flex-end">
          <Grid item xs>
            <Box textAlign="left">
              <Typography variant="h4">Totais</Typography>
            </Box>
          </Grid>
          <Grid item>
            <TextField
              name="name"
              margin="none"
              value={coupon}
              onChange={handleSetCoupon}
              type="text"
              label="Cupom"
              style={{ marginRight: 16 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <MoneyOff />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item>
            <Button
              size="small"
              onClick={handleValidateCoupon}
              color="primary"
              variant="outlined"
              disabled={couponButtonDisabled}
            >
              Validar cupom
            </Button>
          </Grid>
          {/* <Grid item>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={handleUseCredits}
              disabled={userWalletValue === 0}
            >
              {userWalletDiscount ? (
                'Remover Créditos'
              ) : (
                <>
                  Usar créditos 
                  <AppCurrencyFormat value={userWalletValue || 0} />
                </>
              )}
            </Button>
          </Grid> */}
        </Grid>
      </Box>

      <Box>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Total por Passageiro Ida ({selectedPassengers.length})
              </TableCell>
              <TableCell align="right">
                <AppCurrencyFormat value={departTrip.ticketValue} />
              </TableCell>
            </TableRow>
            {returnTrip && returnTrip.id && (
              <TableRow>
                <TableCell component="th" scope="row">
                  Total por Passageiro Volta ({selectedPassengers.length})
                </TableCell>
                <TableCell align="right">
                  <AppCurrencyFormat value={returnTrip.ticketValue} />
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell component="th" scope="row">
                Subtotal
              </TableCell>
              <TableCell align="right">
                <AppCurrencyFormat
                  value={
                    departTrip.ticketValue * selectedPassengers.length +
                    ((returnTrip && returnTrip.ticketValue) || 0) *
                      selectedPassengers.length
                  }
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                Desconto (Créditos R$)
              </TableCell>
              <TableCell align="right">
                <AppCurrencyFormat value={userWalletDiscount || 0} />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                Desconto (Cupom R$)
              </TableCell>
              <TableCell align="right">
                <AppCurrencyFormat
                  value={(couponApplicable && couponApplicable.value) || 0}
                />
              </TableCell>
            </TableRow>
            {!firstTrip && !couponApplicable && discountValue !== 0 && (
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ color: '#0FB8D4' }}
                >
                  Desconto Primeira Viagem 🥳
                </TableCell>
                <TableCell align="right">
                  <AppCurrencyFormat value={discountValue} />
                </TableCell>
              </TableRow>
            )}

            <TableRow>
              <TableCell component="th" scope="row">
                <b>Total a pagar</b>
              </TableCell>
              <TableCell align="right">
                <AppCurrencyFormat value={totalValue} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
}

CheckoutSummary.propTypes = {};

const mapStateToProps = store => ({
  user: store.auth.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      enqueueSnackbar: enqueueSnackbar
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutSummary);
