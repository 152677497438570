import React from 'react';
import { Grid, Tooltip } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import AppConfirmation from '../../shared/app-confirmation/AppConfirmation';
import BookingApiService from '../../../services/BookingApiService';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { enqueueSnackbar } from '../../../actions';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import PixInfo from '../../checkout/trip-summary/pix-infos/PixInfo';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(14),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary,
  },
  secondaryHeadingSmall: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.secondary,
    fontWeight: 'bolder',
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
    padding: '8px 24px 8px',
  },
  desktopBox: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '16px 0 0 0',
    [theme.breakpoints.up('sm')]: {
      borderTop: 'none',
      borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
      margin: '0 0 0 16px',
      padding: '0 0 0 16px',
      height: '100%',
      paddingBottom: '20%',
    },
  },
}));

function BookingPixPanel(props) {
  const { booking, expanded, onBookingCanceled, enqueueSnackbar, user} = props;
  const classes = useStyles();
  const [internalExpanded, setInternalExpanded] = React.useState(
    expanded || false
  );
  const [confirmationModalOpen, setConfirmationModalOpen] =
    React.useState(false);

  const handleBookingCancel = () => {
    setConfirmationModalOpen(true);
  };

  const refundTrip = (code) => {
    BookingApiService.refundTrip(code)
      .then((resp) => {
        enqueueSnackbar({
          message: "Viagem estornada com sucesso!",
          options: {
            key: "refundTrip",
            autoHideDuration: 3000,
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          },
        });
        props.setReload(true);
      })
      .catch((e) => {
        if (e.status === 406) {
          enqueueSnackbar({
            message: e.data.error,
            options: {
              key: "refundTripError",
              autoHideDuration: 3000,
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
            },
          });
        }
      });
  };

  const tripSteps = [];
  tripSteps.push({
    title: booking.trip.departure.city.name,
    subTitle: booking.trip.departure.address,
  });

  tripSteps.push(
    ...(booking.trip.tripsStop || []).map((tripStop) => {
      return {
        title: tripStop.departure.city.name,
        subTitle: tripStop.departure.address,
      };
    })
  );

  tripSteps.push({
    title: booking.trip.arrival.city.name,
    subTitle: booking.trip.arrival.address,
  });

  const isMobile = useMediaQuery('(max-width:768px)');
  const [loading,setLoading] = React.useState(false)
  return (
    <>
      <AppConfirmation
        title="Deseja realmente cancelar a reserva?"
        message={
          <>
            <Typography>
              Cancelamento: Ao cancelar sua reserva o valor será estornado na
              sua fatura. O cancelamento é permitido até 3 horas antes da
              viagem. Atenção para as regras de cancelamento/estorno. Será
              aplicada multa de 5% do valor da reserva em caso de cancelamento
              dentro do período permitido. Após impresso, o bilhete não pode ser
              cancelado pelo site ou App, é necessário ir até o guichê.
            </Typography>
          </>
        }
        open={confirmationModalOpen}
        handleClose={() => setConfirmationModalOpen(false)}
        actions={[
          {
            label: "Cancelar",
            onClick: () => {
              setLoading(false);
              setConfirmationModalOpen(false);
            },
          },
          {
            label: "Confirmar",
            disabled: loading,
            onClick: () => {
              setLoading(true);
              BookingApiService.cancelBooking(booking.bookingCode)
                .then(
                  (_success) => {
                    enqueueSnackbar({
                      message: "Reserva cancelada com sucesso!",
                      options: {
                        key: "cancelBookingSnack",
                        autoHideDuration: 3000,
                        variant: "success",
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "center",
                        },
                      },
                    });
                    props.setReload(true);
                    onBookingCanceled && onBookingCanceled();
                  },
                  (error) => {
                    let message = "Não foi possivel cancelar sua reserva.";
                    if (error && error.status === 406) {
                      message = error.data.error;
                    }

                    enqueueSnackbar({
                      message: message,
                      options: {
                        key: "cancelBookingSnack",
                        autoHideDuration: 5000,
                        variant: "error",
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "center",
                        },
                      },
                    });
                  }
                )
                .finally(() => {
                  setLoading(false);
                  setConfirmationModalOpen(false);
                });
            },
            buttonProps: {
              color: "primary",
            },
          },
        ]}
      />
      <ExpansionPanel
        defaultExpanded={expanded}
        expanded={internalExpanded}
        onChange={() => setInternalExpanded((prev) => !prev)}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="Bookings"
          id="bookings-header"
        >
          <Grid container spacing={1}>
            <Grid item xs={isMobile ? 12 : 5}>
              <Typography className={classes.Heading}>
                {user.type === 5 ? (
                  <>{booking.user.name}</>
                ) : (
                  <>
                    {`Destino: ${booking.trip.arrival.city.name}, ${booking.trip.arrival.city.uf}`}
                  </>
                )}
              </Typography>
            </Grid>
            <Grid item xs={isMobile ? 9 : 3}>
              <Tooltip
                title={moment(new Date(booking.trip.etdTimestamp)).format(
                  "LLLL"
                )}
              >
                <Typography className={classes.secondaryHeading}>
                  {moment(new Date(booking.trip.etdTimestamp)).format("lll")}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </ExpansionPanelSummary>

          <ExpansionPanelDetails className={classes.details}>
            <PixInfo />
          </ExpansionPanelDetails>

        <Divider />

        <ExpansionPanelActions>
          {booking.statusId === 7 && !booking.refunded && (
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              onClick={(e) => refundTrip(booking.bookingCode)}
            >
              Estornar
            </Button>
          )}
          {booking.statusId !== 7 &&
            booking.statusId !== 4 &&
            booking.statusId !== 5 && (
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                onClick={handleBookingCancel}
              >
                Cancelar
              </Button>
            )}
        </ExpansionPanelActions>
      </ExpansionPanel>
    </>
  );
}

const mapStateToProps = (store) => ({
  user: store.auth.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      enqueueSnackbar: enqueueSnackbar,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BookingPixPanel)
);
