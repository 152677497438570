import { Box, Button, Container, Grid } from "@material-ui/core";
import React from "react";
import SuggestTrip from "../suggest-trip";
import TripCard from "../trip-card/TripCard";
import TripCardSkeleton from "../trip-card/trip-card-skeleton";

import "./tripCardContainer.css"

function TripCardsContainer(props) {
  const {
    title,
    subtitle,
    skeletonCount = 3,
    trips,
    partnerTrips,
    isLoading,
    isLoadingPartner,
    noResultsRender,
    onSelectTrip,
    firstTrip,
    settings,
  } = props;
  const hasNoResultsRender = Boolean(noResultsRender);
  const [suggestTripIsOpen, setSuggestTripIsOpen] = React.useState(false);
  const [tripsEdit, setTripsEdit] = React.useState([]);

  React.useEffect(() => {
    setTripsEdit(trips);
    if (firstTrip && settings) {
      const tripsEdited = trips.map((trip) => {
        settings.map((discount) => {
          if (trip.etd >= discount.startDate && trip.etd <= discount.endDate) {
            if (discount.typeValue === "V") {
              trip.ticketFirstTrip = trip.ticketValue - discount.value;
            } else {
              trip.ticketFirstTrip =
                trip.ticketValue -
                parseFloat(trip.ticketValue * (discount.value / 100)).toFixed(
                  2
                );
            }
          }
        });
        return trip;
      });
      setTripsEdit(tripsEdited);
    }
  }, [trips, firstTrip, settings, partnerTrips]);

  React.useEffect(() => {
  }, [tripsEdit, partnerTrips]);

  const handleCloseSuggestTrip = () => {
    setSuggestTripIsOpen(false);
  };

  return (
    <>
      <Container className="Container">
        {Boolean(title) && <h1>{title}</h1>}
        {Boolean(subtitle) && <h3>{subtitle}</h3>}

        <TripCardSkeleton visible={isLoading} count={skeletonCount} />
        <TripCardSkeleton visible={isLoadingPartner} count={skeletonCount} />

        <Container className="containerOptionsTickets">
        {tripsEdit &&
          tripsEdit.length > 0 &&
          tripsEdit.map((trip) => (
            <Box key={trip.id + Math.random()} className="boxTripCard">
              <TripCard
                trip={trip}
                showCityName={true}
                onSelectTrip={onSelectTrip}
              />
            </Box>
          ))}

        {!isLoading && (tripsEdit === null || tripsEdit.length === 0) && (
          <>
            {hasNoResultsRender && noResultsRender}

            {!hasNoResultsRender && (
              <Box textAlign="center" width="100%">
                <h2>Ainda não existe nenhuma viagem criada para esta data</h2>

                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setSuggestTripIsOpen(true)}
                >
                  Sugerir linha
                </Button>
              </Box>
            )}
          </>
        )}
        </Container>
      </Container>

      <SuggestTrip
        open={suggestTripIsOpen}
        handleClose={handleCloseSuggestTrip}
      />
    </>
  );
}

export default TripCardsContainer;
