import {
  SET_BOOKING
} from '../actions/checkoutActions';

const initialState = {
  departTrip: null,
  returnTrip: null,
  passengers: [],
  coupon: null,
  paymentData: null,
  paymentSuccess: null,
  paymentError: null,
  booking:null
};

const checkoutReducer = (state = initialState, action) => {

  switch (action.type) {
    case SET_BOOKING:{
      return{
        ...state,
        booking:action.payload
      }
    }
    // case USER_LAST_SEARCHES_SUCCESS: {
    //   // console.log("action USER_LAST_SEARCHES_SUCCESS", action);
    //   return {
    //     ...state,
    //     isLoading: false,
    //     trips: action.trips,
    //     isEmpty: action.trips === null || action.trips.length === 0,
    //   };
    // }
    // case USER_LAST_SEARCHES_ERROR:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     error: state.error,
    //     trips: [],
    //   };
    default:
      return state;
  }
};

export default checkoutReducer;
