import React from "react";
import { Box, Button, Container, Divider, Grid } from "@material-ui/core";
import { bindActionCreators } from "redux";
import AppBar from "@material-ui/core/AppBar";
import ROUTES from "../../../constants/routes";
import Slide from "@material-ui/core/Slide";
import Tab from "@material-ui/core/Tab";
import { TabLabel } from "./tab-label/TabLabel";
import { TabPanel } from "./tab-panel/TabPanel";
import Tabs from "@material-ui/core/Tabs";
import { connect, useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { searchTrips } from "../../../actions";

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

function TripSearchResults(props) {
  const {
    isRoundTrip,
    cityDeparture,
    cityArrival,
    departDate,
    returnDate,
    showResults,
    tripsGoing,
    isLoadingGoing,
    tripsReturn,
    isLoadingReturn,
    tripsPartnerGoing,
    isLoadingPartnerGoing,
    tripsPartnerReturn,
    isLoadingPartnerReturn,
    settings,
    firstTrip,
    history,
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const searchTripState = useSelector((state) => state.searchTripState);
  const user = useSelector((state) => state.user);
 
  const [tab, setTab] = React.useState("going");
  const [isGoingSelected, setIsGoingSelected] = React.useState(false);
  const [selectedDateDepart, setSelectedDateDepart] = React.useState(
    departDate
  );
  const [selectedDateReturn, setSelectedReturnDate] = React.useState(
    returnDate
  );

  function handleChange(_, newTab) {
    console.log("handleChange", newTab);
    setTab(newTab);
  }

  const getTripByDepartDate = (departDate) => {
    setSelectedDateDepart(departDate);
    console.log("getTripByDepartDate", departDate);
    const detail = true;
    dispatch(
      searchTrips(
        searchTripState.cityDeparture,
        searchTripState.cityArrival,
        new Date(departDate),
        searchTripState.returnDate,
        true,
        user && user.uidFirebase,
        () => {
          console.log("DEU CERTO!!!");
        },
        detail
      )
    );
  };
  const getTripByReturnDate = (returnDate) => {
    setSelectedReturnDate(returnDate);
    console.log("getTripByDepartDate returnDate", returnDate);
    const detail = true;
    dispatch(
      searchTrips(
        searchTripState.cityDeparture,
        searchTripState.cityArrival,
        searchTripState.departDate,
        new Date(returnDate),
        true,
        user && user.uidFirebase,
        () => {
          console.log("DEU CERTO!!!");
        },
        detail
      )
    );
  };

  const saveToStorage = (isGoing, trip) => {


    sessionStorage.setItem(
      isGoing ? "departTrip" : "returnTrip",
      JSON.stringify(trip)
    );
  };

  const onSelectTrip = (isGoing, goTo) => ({ trip, onSelectTripDefault }) => {
    saveToStorage(isGoing, trip);

    if (!isGoing || (isGoing && !isRoundTrip)) {
      goToSelectPassenger();
    } else if (isGoing && isRoundTrip) {
      setTab("return");
    }

    if (goTo) {
      onSelectTripDefault(trip);
    } else {
      setIsGoingSelected(true);
    }
  };

  const goToSelectPassenger = () => {
    history.push(ROUTES.CHECKOUT);
  };

  const selectOnlyDeparture = () => {
    sessionStorage.removeItem("returnTrip");
    goToSelectPassenger();
  };

  return (
    <>
      {showResults && (
        <div className={classes.root}>
          <AppBar position='static'>
            <Typography
              component='div'
              style={{
                backgroundColor: "#FFF",
                color: "#02B3D4",
                width: "100%",
              }}
            >
              <Tabs
                value={tab}
                onChange={handleChange}
                centered
                aria-label='Ida e Volta'
              >
                <Tab
                  value='going'
                  onClick={(e, value) => getTripByDepartDate(departDate)}
                  label={
                    <TabLabel
                      showDate={false}
                      showLabel={true}
                      isGoing={true}
                      cityDepartureName={cityDeparture && cityDeparture.name}
                      cityArrivalName={cityArrival && cityArrival.name}
                      date={departDate}
                    />
                  }
                  wrapped
                  {...a11yProps("going")}
                />
                {isRoundTrip && (
                  <Tab
                    value='return'
                    onClick={(e, value) => getTripByReturnDate(returnDate)}
                    label={
                      <TabLabel
                        showDate={false}
                        showLabel={true}
                        isGoing={false}
                        cityDepartureName={cityArrival && cityArrival.name}
                        cityArrivalName={cityDeparture && cityDeparture.name}
                        date={returnDate}
                      />
                    }
                    wrapped
                    {...a11yProps("return")}
                  />
                )}
              </Tabs>
            </Typography>
          </AppBar>
          {/* Datas Sugestao IDA */}
          {tab === "going" && (
            <AppBar position="static">
              <Typography
                component='div'
                style={{
                  backgroundColor: "#FFF",
                  color: "#02B3D4",
                  width: "100%",
                }}
              >
                <Tabs
                  value={selectedDateDepart}
                  onChange={(e, value) => getTripByDepartDate(value)}
                  centered
                  aria-label='Ida e Volta'
                >
                  <Tab
                    value={new Date(departDate).setDate(
                      departDate.getDate() - 3
                    )}
                    label={
                      <TabLabel
                        oldDate={true}
                        //  isGoing={true}
                        cityDepartureName={cityDeparture && cityDeparture.name}
                        cityArrivalName={cityArrival && cityArrival.name}
                        date={new Date(departDate).setDate(
                          departDate.getDate() - 3
                        )}
                      />
                    }
                    wrapped
                    {...a11yProps("going")}
                  />
                  <Tab
                    value={new Date(departDate).setDate(
                      departDate.getDate() - 2
                    )}
                    onChange={(e, value) => getTripByDepartDate(value)}
                    label={
                      <TabLabel
                        oldDate={true}
                        //  isGoing={true}
                        cityDepartureName={cityDeparture && cityDeparture.name}
                        cityArrivalName={cityArrival && cityArrival.name}
                        date={new Date(departDate).setDate(
                          departDate.getDate() - 2
                        )}
                      />
                    }
                    wrapped
                    {...a11yProps("going")}
                  />
                  <Tab
                    value={new Date(departDate).setDate(
                      departDate.getDate() - 1
                    )}
                    onChange={(e, value) => getTripByDepartDate(value)}
                    label={
                      <TabLabel
                        oldDate={true}
                        //  isGoing={true}
                        cityDepartureName={cityDeparture && cityDeparture.name}
                        cityArrivalName={cityArrival && cityArrival.name}
                        date={new Date(departDate).setDate(
                          departDate.getDate() - 1
                        )}
                      />
                    }
                    wrapped
                    {...a11yProps("going")}
                  />
                  <Tab
                    value={departDate}
                    onChange={(e, value) => getTripByDepartDate(value)}
                    label={
                      <TabLabel
                        showLabel={false}
                        isGoing={true}
                        cityDepartureName={cityDeparture && cityDeparture.name}
                        cityArrivalName={cityArrival && cityArrival.name}
                        date={departDate}
                      />
                    }
                    wrapped
                    {...a11yProps("going")}
                  />
                  <Tab
                    value={new Date(departDate).setDate(
                      departDate.getDate() + 1
                    )}
                    onChange={(e, value) => getTripByDepartDate(value)}
                    label={
                      <TabLabel
                        oldDate={true}
                        //  isGoing={true}
                        cityDepartureName={cityDeparture && cityDeparture.name}
                        cityArrivalName={cityArrival && cityArrival.name}
                        date={new Date(departDate).setDate(
                          departDate.getDate() + 1
                        )}
                      />
                    }
                    wrapped
                    {...a11yProps("going")}
                  />
                  <Tab
                    value={new Date(departDate).setDate(
                      departDate.getDate() + 2
                    )}
                    onChange={(e, value) => getTripByDepartDate(value)}
                    label={
                      <TabLabel
                        oldDate={true}
                        //  isGoing={true}
                        cityDepartureName={cityDeparture && cityDeparture.name}
                        cityArrivalName={cityArrival && cityArrival.name}
                        date={new Date(departDate).setDate(
                          departDate.getDate() + 2
                        )}
                      />
                    }
                    wrapped
                    {...a11yProps("going")}
                  />
                  <Tab
                    value={new Date(departDate).setDate(
                      departDate.getDate() + 3
                    )}
                    onChange={(e, value) => getTripByDepartDate(value)}
                    label={
                      <TabLabel
                        nextDate={true}
                        //  isGoing={true}
                        cityDepartureName={cityDeparture && cityDeparture.name}
                        cityArrivalName={cityArrival && cityArrival.name}
                        date={new Date(departDate).setDate(
                          departDate.getDate() + 3
                        )}
                      />
                    }
                    wrapped
                    {...a11yProps("going")}
                  />
                  {/* Fim das Datas Novas */}
                </Tabs>
              </Typography>
            </AppBar>
          )}
          {returnDate && tab === "return" && (
            <AppBar position="static">
              <Typography
                component="div"
                style={{
                  backgroundColor: "#FFF",
                  color: "#02B3D4",
                  width: "100%",
                }}
              >
                <Tabs
                  value={selectedDateReturn}
                  onChange={(e, value) => getTripByReturnDate(value)}
                  centered
                  aria-label="Ida e Volta"
                >
                  {/* Datas Antigas */}
                  <Tab
                    value={new Date(returnDate).setDate(
                      returnDate.getDate() - 3
                    )}
                    onChange={(e, value) => getTripByReturnDate(value)}
                    label={
                      <TabLabel
                        oldDate={true}
                        //  isGoing={true}
                        cityDepartureName={cityDeparture && cityDeparture.name}
                        cityArrivalName={cityArrival && cityArrival.name}
                        date={new Date(returnDate).setDate(
                          returnDate.getDate() - 3
                        )}
                      />
                    }
                    wrapped
                    {...a11yProps("going")}
                  />
                  <Tab
                    value={new Date(returnDate).setDate(
                      returnDate.getDate() - 3
                    )}
                    onChange={(e, value) => getTripByReturnDate(value)}
                    label={
                      <TabLabel
                        oldDate={true}
                        //  isGoing={true}
                        cityDepartureName={cityDeparture && cityDeparture.name}
                        cityArrivalName={cityArrival && cityArrival.name}
                        date={new Date(returnDate).setDate(
                          returnDate.getDate() - 2
                        )}
                      />
                    }
                    wrapped
                    {...a11yProps("going")}
                  />
                  <Tab
                    value={new Date(returnDate).setDate(
                      returnDate.getDate() - 1
                    )}
                    onChange={(e, value) => getTripByReturnDate(value)}
                    label={
                      <TabLabel
                        oldDate={true}
                        //  isGoing={true}
                        cityDepartureName={cityDeparture && cityDeparture.name}
                        cityArrivalName={cityArrival && cityArrival.name}
                        date={new Date(returnDate).setDate(
                          returnDate.getDate() - 1
                        )}
                      />
                    }
                    wrapped
                    {...a11yProps("going")}
                  />
                  <Tab
                    value={returnDate}
                    onChange={(e, value) => getTripByReturnDate(value)}
                    label={
                      <TabLabel
                        // showLabel={true}
                        isGoing={true}
                        cityDepartureName={cityDeparture && cityDeparture.name}
                        cityArrivalName={cityArrival && cityArrival.name}
                        date={returnDate}
                      />
                    }
                    wrapped
                    {...a11yProps("going")}
                  />

                  <Tab
                    value={new Date(returnDate).setDate(
                      returnDate.getDate() + 1
                    )}
                    onChange={(e, value) => getTripByReturnDate(value)}
                    label={
                      <TabLabel
                        oldDate={true}
                        //  isGoing={true}
                        cityDepartureName={cityDeparture && cityDeparture.name}
                        cityArrivalName={cityArrival && cityArrival.name}
                        date={new Date(returnDate).setDate(
                          returnDate.getDate() + 1
                        )}
                      />
                    }
                    wrapped
                    {...a11yProps("going")}
                  />
                  <Tab
                    value={new Date(returnDate).setDate(
                      returnDate.getDate() + 2
                    )}
                    onChange={(e, value) => getTripByReturnDate(value)}
                    label={
                      <TabLabel
                        oldDate={true}
                        //  isGoing={true}
                        cityDepartureName={cityDeparture && cityDeparture.name}
                        cityArrivalName={cityArrival && cityArrival.name}
                        date={new Date(returnDate).setDate(
                          returnDate.getDate() + 2
                        )}
                      />
                    }
                    wrapped
                    {...a11yProps("going")}
                  />
                  <Tab
                    value={new Date(returnDate).setDate(
                      returnDate.getDate() + 3
                    )}
                    onChange={(e, value) => getTripByReturnDate(value)}
                    label={
                      <TabLabel
                        nextDate={true}
                        //  isGoing={true}
                        cityDepartureName={cityDeparture && cityDeparture.name}
                        cityArrivalName={cityArrival && cityArrival.name}
                        date={new Date(returnDate).setDate(
                          returnDate.getDate() + 3
                        )}
                      />
                    }
                    wrapped
                    {...a11yProps("going")}
                  />
                  {/* Fim das Datas Novas */}
                </Tabs>
              </Typography>
            </AppBar>
          )}
          <Slide in={tab === "going"} direction="right">
            <Box>
              <TabPanel
                value={tab}
                settings={settings}
                firstTrip={firstTrip}
                key='going'
                index='going'
                trips={tripsGoing}
                tripsPartner={tripsPartnerGoing}
                isLoading={isLoadingGoing}
                isLoadingPartner={isLoadingPartnerGoing}
                cityDepartureName={cityDeparture && cityDeparture.name}
                cityArrivalName={cityArrival && cityArrival.name}
                onSelectTrip={onSelectTrip(true, !isRoundTrip)}
              />
            </Box>
          </Slide>

          <Slide in={tab === "return"} direction="left">
            <Box>
              <TabPanel
                value={tab}
                settings={settings}
                firstTrip={firstTrip}
                key='return'
                index='return'
                trips={tripsReturn}
                tripsPartner={tripsPartnerReturn}
                isLoading={isLoadingReturn}
                isLoadingPartner={isLoadingPartnerReturn}
                cityArrivalName={cityDeparture && cityDeparture.name}
                cityDepartureName={cityArrival && cityArrival.name}
                onSelectTrip={onSelectTrip(false, isRoundTrip)}
              />

              {isGoingSelected && (
                <Box component={Container} paddingX={4}>
                  <Grid container justify='flex-end'>
                    <Grid item>
                      <Button
                        onClick={selectOnlyDeparture}
                        variant='outlined'
                        color='primary'
                      >
                        Reservar somente ida
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Box>
          </Slide>

          <Divider style={{ marginTop: 16 }} />
        </div>
      )}
    </>
  );
}

TripSearchResults.propTypes = {};

const mapStateToProps = (store) => ({
  isRoundTrip: store.searchTripState.isRoundTrip,
  cityDeparture: store.searchTripState.cityDeparture,
  cityArrival: store.searchTripState.cityArrival,
  departDate: store.searchTripState.departDate,
  returnDate: store.searchTripState.returnDate,
  oldDates: store.searchTripState.oldDates,
  showResults: store.searchTripState.showResults,

  tripsGoing: store.searchTripState.tripsGoing,
  isLoadingGoing: store.searchTripState.isLoadingGoing,
  tripsPartnerGoing: store.searchTripState.tripsPartnerGoing,
  isLoadingPartnerGoing: store.searchTripState.isLoadingPartnerGoing,

  tripsReturn: store.searchTripState.tripsReturn,
  isLoadingReturn: store.searchTripState.isLoadingReturn,
  tripsPartnerReturn: store.searchTripState.tripsPartnerReturn,
  isLoadingPartnerReturn: store.searchTripState.isLoadingPartnerReturn,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      searchTrips: searchTrips,
    },
    dispatch
  );
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TripSearchResults)
);
