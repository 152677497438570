import 'date-fns';
import 'react-credit-cards/es/styles-compiled.css';
import {
  Box,
  Button,
  Container,
  Grid,
  Typography
} from '@material-ui/core';
import CheckoutStepper from '../checkout-stepper/CheckoutStepper';
import { Link } from 'react-router-dom';
import ROUTES from '../../../constants/routes';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { enqueueSnackbar } from '../../.././actions';
import {bookingListenerPix} from '../../../actions/bookingListenerAction'
import CheckSuccess from './../../shared/check-success';
//--------------------------------------------------
import './TripSummary.css'
import PixInfo from './pix-infos/PixInfo';

function TripSummary(props) {
  const bookingDatas = JSON.parse(sessionStorage.getItem("checkoutBookingResult"));

  const [cardPayment, setCardPayment] = React.useState();
  
  React.useEffect(() => {
    if (bookingDatas.booking.pixLink == null) {
      setCardPayment(true);
    }
    //eslint-disable-next-line
  }, []);

  var bookingCode = bookingDatas.booking.bookingSegments;
  bookingCode.map((bookingSegmentCode) => {
    return bookingCode = bookingSegmentCode.bookingCode
  })
  return (
    <Box className="box" textAlign="center">
      <CheckoutStepper activeStep={3} />
      <Container>
        <Grid container spacing={3}>
          <Grid
            container
            item
            xs={12}
            direction="column"
            justify="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              {cardPayment
                ?
                (
                  <div>
                    <Typography variant="h5">
                      Pagamento realizado com sucesso!
                    </Typography>
                    <CheckSuccess />
                  </div>
                )
                :
                (
                  <Grid className='pix-infos'>
                    <PixInfo/>
                  </Grid>
                )
              }
            </Grid>
            
            <Grid item>
              <Button
                component={Link}
                to={ROUTES.MY_TRIPS}
                variant="outlined"
                color="primary"
                size="large"
              >
                Ir para Viagens
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

TripSummary.propTypes = {};

const mapStateToProps = store => ({
  error: store.bookingListener.pixExpired,
  success: store.bookingListener.pixPayed,
});

const mapDispatchToProps = dispatch => 
bindActionCreators(
  {
    enqueueSnackbar: enqueueSnackbar,
    bookingListenerPix: bookingListenerPix
  }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(TripSummary));
