export const HOME = "/";
export const DEEP_LINK_SEARCH =
  "/:cityIdDeparture/:cityIdArrival/:departDate/:returnDate?";
export const MY_ACCOUNT = "/minha-conta";
export const PRINT_BOOKING =
  "/minha-conta/minhas-viagens/imprimir-reserva/:bookingId/:passengerId/:bookingCode";
export const MY_TRIPS = "/minha-conta/minhas-viagens";
//export const MY_WALLET = "/minha-conta/carteira";
export const HELP = "/ajuda";
export const LOGIN = "/login";
export const SIGNIN = "/login/signin";
export const SIGNUP = "/login/signup";
export const CHECKOUT = "/checkout/selecionar-passageiros";
export const CHECKOUT_CONFIRMATION = "/checkout/confirme-sua-reserva";
export const CHECKOUT_SUMMARY = "/checkout/resumo-da-reserva";
export const CHECKOUT_SELECT_SEATS = "/checkout/selecao-assento";
export const CHECKOUT_SELECT_RETURN_SEATS = "/checkout/selecao-assento-volta";
export const CHARTER_TERMS_OF_USE = "/termos-de-uso";
export const CHARTER_TERMS_OF_USE_POLITICAS = "/privacy-policy";
export const CHARTER_TERMS_OF_USE_FRETADO = "/termos-de-uso-fretamento";

const ROUTES = {
  HOME,
  DEEP_LINK_SEARCH,
  MY_ACCOUNT,
  MY_TRIPS,
  /*MY_WALLET,*/
  HELP,
  LOGIN,
  SIGNIN,
  SIGNUP,
  CHECKOUT,
  CHECKOUT_CONFIRMATION,
  CHECKOUT_SUMMARY,
  CHECKOUT_SELECT_SEATS,
  CHECKOUT_SELECT_RETURN_SEATS,
  PRINT_BOOKING,
  CHARTER_TERMS_OF_USE,
  CHARTER_TERMS_OF_USE_POLITICAS,
  CHARTER_TERMS_OF_USE_FRETADO,
};

export default ROUTES;
