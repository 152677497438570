import {
  SEARCH_TRIPS,
  SEARCH_TRIPS_GOING_ERROR,
  SEARCH_TRIPS_GOING_SUCCESS,
  SEARCH_TRIPS_RETURN_ERROR,
  SEARCH_TRIPS_RETURN_SUCCESS,
  SEARCH_PARTNER_TRIPS_GOING_ERROR,
  SEARCH_PARTNER_TRIPS_GOING_SUCCESS,
  SEARCH_PARTNER_TRIPS_RETURN_ERROR,
  SEARCH_PARTNER_TRIPS_RETURN_SUCCESS,
} from '../actions/actionTypes';

import facebookPixel from '../utils/facebookPixel';
import googleAnalytics from '../utils/googleAnalytics';

const initialState = {
  isRoundTrip: true,
  cityDeparture: null,
  cityArrival: null,
  departDate: null,
  returnDate: null,
  oldDates: [],

  showResults: false,

  tripsGoing: [],
  isLoadingGoing: false,
  errorGoing: null,
  isEmptyGoing: null,

  tripsReturn: [],
  isLoadingReturn: false,
  errorReturn: null,
  isEmptyReturn: null,
};

// const generateOldDates = date => {
//   return [
//     new Date(date.setDate(date.getDate() - 1)),
//     // new Date(date.setDate(date.getDate() - 2)),
//     // new Date(date.setDate(date.getDate() - 3))
// ]
// }
const searchTripReducer = (state = initialState, action) => {

  switch (action.type) {
    case SEARCH_TRIPS: {

      const departDateSelected = action.departDate;

      const params = {
        isRoundTrip: action.isRoundTrip,
        cityDeparture: action.cityDeparture,
        cityArrival: action.cityArrival,
        // departDate: action.departDate,
        // returnDate: action.returnDate,
      };
      if (action.detail) {
        params.departDate = state.departDate;
        params.returnDate = state.returnDate;
      } else {
        params.departDate = action.departDate;
        params.returnDate = action.returnDate;
      }

      googleAnalytics(
        '',
        { category: 'engagement', action: 'search', label: 'search' },
        ''
      );
      facebookPixel('', { type: 'Search', data: {} });

      return {
        ...state,
        ...params,

        showResults: true,
        isLoadingGoing: true,

        isLoadingReturn: action.isRoundTrip,

        // CLEAN UP EVERY NEW SEARCH
        tripsGoing: [],
        errorGoing: null,
        isEmptyGoing: null,
        tripsReturn: [],
        errorReturn: null,
        isEmptyReturn: null,
      };
    }
    case SEARCH_TRIPS_GOING_SUCCESS: {
      return {
        ...state,
        isLoadingGoing: false,
        tripsGoing: action.trips,
        isEmptyGoing: action.trips === null || action.trips.length === 0,
      };
    }

    case SEARCH_PARTNER_TRIPS_GOING_SUCCESS: {
      return {
        ...state,
        // isLoadingPartnerGoing: false,
        // tripsPartnerGoing: action.trips.data.trips,
        // isEmptyPartnerGoing:
        //   action.trips.data.trips === null ||
        //   action.trips.data.trips.length === 0,
      };
    }

    case SEARCH_TRIPS_GOING_ERROR:
      return {
        ...state,
        isLoadingGoing: false,
        errorGoing: state.error,
      };

    case SEARCH_PARTNER_TRIPS_GOING_ERROR:
      return {
        ...state,
        // isLoadingPartnerGoing: false,
        // errorPartnerGoing: state.error,
      };

    case SEARCH_TRIPS_RETURN_SUCCESS: {
      return {
        ...state,
        isLoadingReturn: false,
        tripsReturn: action.trips,
        isEmptyReturn: action.trips === null || action.trips.length === 0,
      };
    }

    case SEARCH_PARTNER_TRIPS_RETURN_SUCCESS: {
      return {
        ...state,
        // isLoadingPartnerReturn: false,
        // tripsPartnerReturn: action.trips,
        // isEmptyPartnerReturn:
        //   action.trips === null || action.trips.length === 0,
      };
    }

    case SEARCH_TRIPS_RETURN_ERROR:
      return {
        ...state,
        isLoadingReturn: false,
        errorReturn: state.error,
      };

    case SEARCH_PARTNER_TRIPS_RETURN_ERROR:
      return {
        ...state,
        // isLoadingPartnerReturn: false,
        // errorPartnerReturn: state.error,
      };
    default:
      return state;
  }
};

export default searchTripReducer;
