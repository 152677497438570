import {
  // AppBar,
  Box,
  Button,
  Grid,
  IconButton,
  Slide,
  // Toolbar,
  makeStyles,
} from "@material-ui/core";

import CssBaseline from "@material-ui/core/CssBaseline";
import FacebookIcon from "@material-ui/icons/Facebook";
// import Header from './header/Header';
import InstagramIcon from "@material-ui/icons/Instagram";
import { Link } from "react-router-dom";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { MuiThemeProvider } from "@material-ui/core/styles";
import Notifier from "../notifier/Notifier";
import ROUTES from "../../../constants/routes";
import React from "react";
import { SnackbarProvider } from "notistack";
// import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from "@material-ui/icons/YouTube";
import { createMuiTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import appstore from "../../../static/images/appstore.png";
import googleplay from "../../../static/images/googleplay.png";

import { faBlog } from "@fortawesome/free-solid-svg-icons";

const styles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  content: {
    marginBottom: "15px",
    // [theme.breakpoints.down('sm')]: {
    //   marginTop: "90px"
    // },
  },
  button: {
    margin: theme.spacing(0),
    textTransform: "none",
    color: "#FFF",
    fontWeight: 400,
    fontSize: 15,
  },
}));

function AppLayout(props) {
  const classes = styles();
  const RouteComponent = props.component;
  const route = props.route;

  const lightTheme = createMuiTheme({
    typography: {
      useNextVariants: true,
      fontFamily: "'Rubik', sans-serif",
    },
    palette: {
      type: "light",
      background: {
        default: "#FFFFFF",
      },
      primary: {
        main: "#0FB8D4",
        contrastText: "#FFFFFF",
      },
      secondary: {
        main: "#0FB8D4",
        contrastText: "#FFFFFF",
      },
      white: {
        main: "#FFF",
      },
    },
  });

  const downloadPDF = async () => {
    const link = 'https://4bus.s3.sa-east-1.amazonaws.com/CO%CC%81DIGO+DE+CONDUTA+ETICA+PARA+FORNECEDORES+-+4BUS.pdf';
    window.open(link, '_blank', 'popup,scrollbars,resizable');
  };

  return (
    <MuiThemeProvider theme={lightTheme}>
      <SnackbarProvider maxSnack={3} preventDuplicate>
        <CssBaseline />

        <div className={classes.root}>
          <div style={{ minHeight: "69vh"}}>
            <Slide direction="right" in={true}>
              <Box className={classes.content}>
                <RouteComponent route={route} />
              </Box>
            </Slide>
          </div>

          <div>
            <Box>
              <Typography
                component="div"
                style={{
                  paddingBottom: 65,
                  backgroundColor: "#02B3D4",
                  color: "white",
                }}
              >
                <Container>
                  <Grid container direction="row" justify="center" spacing={40}>
                    <Grid item xs={12} sm={12} lg={3}>
                      <br />
                      <h1>Baixe o app</h1>
                      <div>
                        <a href="https://apps.apple.com/br/app/4bus/id1476990913?l=pt">
                          <img
                            src={appstore}
                            style={{ display: "block", marginBottom: 20 }}
                            alt="appstore"
                            height="50"
                          />
                        </a>

                        <a href="https://play.google.com/store/apps/details?id=br.com.fourbusapp">
                          <img
                            src={googleplay}
                            style={{ display: "block" }}
                            alt="googleplay"
                            height="50"
                          />
                        </a>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={3}>
                      <br />
                      <h1>Institucional</h1>
                      <Button
                        component={Link}
                        to={ROUTES.CHARTER_TERMS_OF_USE_FRETADO}
                        className={classes.button}
                      >
                        Termos de uso fretado
                      </Button>
                      <br />
                      <Button
                        component={Link}
                        to={ROUTES.CHARTER_TERMS_OF_USE}
                        className={classes.button}
                      >
                        Termos de uso
                      </Button>
                      <br />
                      <Button
                        component={Link}
                        to={ROUTES.CHARTER_TERMS_OF_USE_POLITICAS}
                        className={classes.button}
                      >
                        Política de privacidade
                      </Button>

                      <br />

                      <Button
                        component={Link}
                        onClick={downloadPDF}
                        className={classes.button}
                      >
                        Termo de conduta ética para fornecedores
                      </Button>

                      <br />
                    </Grid>
                    <Grid item xs={12} sm={8} lg={3}>
                      <br />
                      <h1>Ajuda</h1>
                      <Button
                        component={Link}
                        to={ROUTES.HOME}
                        className={classes.button}
                      >
                        Perguntas frequentes
                      </Button>
                      <br />
                      <Button
                        disabled={true}
                        className={classes.button}
                      >
                        Versão 3.0.1
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={3}>
                      <br />
                      <IconButton
                        size="large"
                        href="https://www.youtube.com/channel/UCck17w3AlLbRuzxlllfc-bA"
                        target="_blank"
                        className={classes.button}
                      >
                        <YouTubeIcon />
                      </IconButton>
                      <IconButton
                        size="large"
                        href="https://www.instagram.com/4BUSBR/"
                        target="_blank"
                        className={classes.button}
                      >
                        <InstagramIcon />
                      </IconButton>
                      <IconButton
                        size="large"
                        href="https://www.facebook.com/4busbr/"
                        target="_blank"
                        className={classes.button}
                      >
                        <FacebookIcon />
                      </IconButton>
                      <IconButton
                        size="large"
                        href=" https://www.linkedin.com/company/4bus"
                        target="_blank"
                        className={classes.button}
                      >
                        <LinkedInIcon />
                      </IconButton>
                      <IconButton
                        size="large"
                        href="https://blog.4bus.com.br/"
                        target="_blank"
                        className={classes.button}
                      >
                        <FontAwesomeIcon icon={faBlog} size="1x" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Container>
              </Typography>
            </Box>
          </div>
        </div>

        <Notifier />
      </SnackbarProvider>
    </MuiThemeProvider>
  );
}

AppLayout.propTypes = {};

export default AppLayout;
