import authReducer from './authReducer';
import checkoutReducer from './checkoutReducer';
import { combineReducers } from 'redux';
import customerReducer from './customerReducer';
import { featuredTripsReducer } from './featuredTripsReducer';
import forSaleTripsReducer from './forSaleTripsReducer';
import notificationsReducer from './notificationsReducer';
import searchTripReducer from './searchTripReducer';
import userLastSearchesReducer from './userLastSearchesReducer';
import bookingListenerReducer from './bookingListenerReducer'

const Reducers = combineReducers({
  notifications: notificationsReducer,
  searchTripState: searchTripReducer,
  featuredTrips: featuredTripsReducer,
  forSaleTrips: forSaleTripsReducer,
  userLastSearches: userLastSearchesReducer,
  auth: authReducer,
  customer: customerReducer,
  checkout: checkoutReducer,
  bookingListener: bookingListenerReducer
});

export default Reducers;
