import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import CheckSuccess from "../../../shared/check-success";

const PixInfo = () => {
  const bookingDatas = JSON.parse(
    sessionStorage.getItem("checkoutBookingResult")
  );
  const [message, setMessage] = useState("");
  const [isExpired, setIsExpired] = useState(false);
  const [isPayed, setIsPayed] = useState(false);

  const { pixQrcode, pixQrcodeBase64, pixExpiration } =
    bookingDatas && bookingDatas.booking ? bookingDatas.booking : {};

  useEffect(() => {
    if (!pixQrcode || !pixQrcodeBase64 || !pixExpiration) {
      setIsExpired(true);
    }
  }, [pixQrcode, pixQrcodeBase64, pixExpiration]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const actualDate = moment();
      const expirationDate = moment(pixExpiration);
      if (actualDate.isAfter(expirationDate)) {
        setIsExpired(true);
        clearInterval(intervalId);
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [pixExpiration]);

  const copyPixLink = () => {
    try {
      navigator.clipboard.writeText(pixQrcode);
      setMessage("Copiado com sucesso!");
    } catch (err) {
      setMessage(
        "Erro inesperado ao copiar! caso o erro persistir, contato o suporte."
      );
    }
    setTimeout(() => {
      setMessage("");
    }, 2000);
  };

  if (bookingDatas && bookingDatas.pixData && bookingDatas.pixData.statusId !== 15) {
    switch (bookingDatas.pixData.statusId) {
      case 3:
        setIsPayed(true);
        break;
      case 4:
        setIsExpired(true);
        break;
      default:
        break;
    }
    return;
  }

  if (isExpired) {
    sessionStorage.removeItem("checkoutBookingResult");
  }

  return (
    <div>
      <Box alignItems="center" justifyContent="center">
        {isPayed ? (
          <>
            <Grid item>
              <CheckSuccess />
            </Grid>
          
            <Grid item>
              <Typography variant="h6">
                Pagamento recebido
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="h4">
                Reserva realizada com sucesso!
              </Typography>
            </Grid>
          </>
        ) : (
          <>
            <Typography variant="h5" align="center" className="infos-about-pix">
              {isExpired
                ? "Reserva cancelada"
                : "Para concluir a reserva, por favor efetue o pagamento em seu banco copiando o Código ou lendo o QR code"}
            </Typography>

            <Typography variant="h6" align="center" className="infos-about-pix">
              {isExpired
                ? ""
                : "Você será notificado quando o pagamento for recebido."}
            </Typography>

            <Grid align="center">
              <span>{message}</span>
              {isExpired ? (
                <></>
              ) : (
                <Button
                  variant="outlined"
                  align="center"
                  color="primary"
                  onClick={copyPixLink}
                >
                  {pixQrcode}
                  <FileCopyIcon />
                </Button>
              )}

              {isExpired ? (
                <Typography
                  variant="h5"
                  align="center"
                  className="info-about-pix"
                >
                  O código expirou!
                </Typography>
              ) : (
                <img
                  className="qr-code-box"
                  src={`data:image/jpeg;base64,${pixQrcodeBase64}`}
                  alt="Pix qr code"
                />
              )}
            </Grid>

            {isExpired ? (
              <></>
            ) : (
              <Box align="center">
                <Typography variant="p">
                  O Código irá se expirar {moment(pixExpiration).calendar()}
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>
    </div>
  );
};

export default PixInfo;
