import 'date-fns';
import 'react-credit-cards/es/styles-compiled.css';

import React, { useEffect } from 'react';
import TripApiService from '../../../services/TripApiService';
import { Box, Container, Grid, makeStyles } from '@material-ui/core';

import CheckoutStepper from '../checkout-stepper/CheckoutStepper';
import CheckoutSummary from '../checkout-summary/CheckoutSummary';
import CheckoutTripSummary from '../checkout-trip-summary/CheckoutTripSummary';
import LoadingOverlay from 'react-loading-overlay';
import PassengersList from '../passengers-list/PassengersList';
import Payment from '../payment/Payment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { enqueueSnackbar } from '../../../actions';
import { withRouter } from 'react-router-dom';

import facebookPixel from '../../../utils/facebookPixel';
import googleAnalytics from '../../../utils/googleAnalytics';

const styles = makeStyles((theme) => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
}));

function CheckoutConfirmation(props) {
  const classes = styles();
  const [isSaving, setIsSaving] = React.useState(false);
  const [discount, setDiscount] = React.useState(null);

  // useEffect(() => {
  //   TripApiService.getTripDiscount()
  //     .then(res => {
  //       if(res.status === 200){
  //         setDiscount(res.data)
  //       }
  //     })
  //     .catch(err => console.log('getTripDiscount err', err));
  // }, []);

  useEffect(() => {
    googleAnalytics(
      '',
      {
        category: 'ecommerce',
        action: 'begin_checkout',
        label: 'begin_checkout',
      },
      ''
    );
    facebookPixel('', { type: 'InitiateCheckout', data: {} });
    sessionStorage.removeItem('USE_CREDITS');
    sessionStorage.removeItem('COUPON');
  }, []);

  const handleOnIsSaving = (isSaving) => {
    window.scrollTo(0, 80);
    setIsSaving(isSaving);
  };

  return (
    <LoadingOverlay
      spinner
      text='Finalizando reserva, por favor aguarde...'
      active={isSaving}
    >
      <Box className={classes.root}>
        <CheckoutStepper activeStep={2} />
        <Container>
          <Grid container justify='center' spacing={4}>
            {/* <Grid
              container
              // direction="column"
              justify="flex-start"
              alignItems="stretch"
              spacing={2}
            > */}
            <Grid item xs={12} sm={12} md={6}>
              <CheckoutTripSummary />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <PassengersList />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <CheckoutSummary discount={discount} />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Payment onIsSaving={handleOnIsSaving} />
            </Grid>
          </Grid>
          {/* </Grid> */}
        </Container>
      </Box>
    </LoadingOverlay>
  );
}

CheckoutConfirmation.propTypes = {};

const mapStateToProps = (store) => ({
  user: store.auth.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      enqueueSnackbar: enqueueSnackbar,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CheckoutConfirmation));
