import { db } from '../firebase';
import moment from 'moment';
import TripApiService from '../services/TripApiService';

export default class TripRepository {
  getDetails = (tripId) => {
    return db.collection('trip').where('id', '==', tripId).get();
  };
  searchTrip = (
    departureCityId,
    arrivalCityId,
    departureDate,
    callbackSucess
  ) => {
    //let dateSelected = new Date(departureDate).setHours(0, 0, 0, 0);
    //let endDateSelected = new Date(departureDate).setHours(23, 59, 59, 999);
    // ALTERADO POR KARLA EM 06/06/2021 POIS O FIREBASE BUSCA A ETD GMT -3 ENTÃO VIAGENS DE MEIA NOITE E MEIA POR EXEMPLO ESTAVAM APARECENDO NO DIA ANTERIOR
    let dateSelected = new Date(moment(new Date(departureDate)).add(-1,'days')).setHours(21, 0, 0, 0);
    let endDateSelected = new Date(departureDate).setHours(20, 59, 59, 999);
    TripApiService.getTotalbusTrips(
      departureCityId,
      arrivalCityId,
      moment(departureDate).format('YYYY-MM-DD')
    )
      .then((res) => {
        setTimeout(() => {}, 1000);
      })
      .catch((error) => {});

    return (
      db
        .collection('trip')
        .where('departure.cityId', '==', departureCityId)
        .where('arrival.cityId', '==', arrivalCityId)
        .where('etd', '>=', dateSelected)
        .where('etd', '<=', endDateSelected)
        // .limit(50)
        .onSnapshot((snapshot) => {
          const data = snapshot.docs
            .map((i) => i.data())
            .filter(
              (i) =>
                i.statusId === 2 ||
                i.statusId === 3 ||
                i.statusId === 8 ||
                i.statusId === 14
            );

          callbackSucess(data);
          return data;
        })
      // .then(
      //   (snapshot) => {
      //     const data = snapshot.docs
      //       .map((i) => i.data())
      //       .filter(
      //         (i) =>
      //           i.statusId === 2 ||
      //           i.statusId === 3 ||
      //           i.statusId === 8 ||
      //           i.statusId === 14
      //       );
      //     // console.log('data', data);
      //     return data;
      //   },
      //   (error) => {
      //     // console.log('error', error);
      //     return error;
      //   }
      // )
    );
  };

  // searchTripTotalbus = (departureCityId, arrivalCityId, departureDate) => {
  //   let dateSelected = new Date(departureDate).setHours(0, 0, 0, 0);
  //   let endDateSelected = new Date(departureDate).setHours(23, 59, 59, 999);

  //   return TripApiService.getTotalbusTrips(
  //     departureCityId,
  //     arrivalCityId,
  //     moment(departureDate).format('YYYY-MM-DD')
  //   )
  //     .then((res) => {
  //       return res;
  //     })
  //     .catch((error) => {
  //       return error;
  //     });
  // };
  getForSaleTrips = (callbackSuccess, callbackError) => {

    let startDate = moment(new Date()).format('x')
    let endDate = moment(new Date()).add(6,'days').format('x')
    let saleTrips = db.collection('trip')
      .where('statusId', 'in', [2,3])
      .where('etd', '>', parseInt(startDate))
      .where('etd', '<=', parseInt(endDate))
      .where("sale", "==", true)

    return saleTrips.onSnapshot(documentSnapshots => {
      let trips = []
      documentSnapshots.docs.forEach(doc => {        
        if (!!doc) {
          var trip = doc.data()
          trips.push(trip)
        }
      })

      callbackSuccess(trips)
      return trips
    }, error => callbackError())
  };
  getFeaturedTrips = (status) => {
    return db
      .collection('trip')
      .where('highlightTrip', '==', true)
      .where('status.id', '==', status)
      .limit(14)
      .get();
  };

  getUserLastSearches = (userId) => {
    return (
      db
        .collection('users')
        .doc(userId)
        .collection('lastSearches')
        // .orderBy('dateDepart', 'desc')
        .limit(3)
        .get()
    );
  };

  saveUserLastSearch = (
    userId,
    departureCity,
    arrivalCity,
    departureDate,
    returnDate
  ) => {
    const date = moment(
      moment.utc(departureDate).format('YYYY-MM-DD 00:00:00.0000')
    );

    const startDateRange = date.valueOf();

    const dateReturn = moment(
      moment.utc(returnDate).format('YYYY-MM-DD 00:00:00.0000')
    );

    const createAt = moment().valueOf();

    const endDateRange = dateReturn.add(1, 'days').valueOf();

    const dCity = {
      id: departureCity.id,
      name: departureCity.name,
      uf: departureCity.uf,
    };

    const aCity = {
      id: arrivalCity.id,
      name: arrivalCity.name,
      uf: arrivalCity.uf,
    };

    const searchDoc = {
      departureCity: dCity,
      arrivalCity: aCity,
      dateDepart: startDateRange,
      dateReturn: dateReturn.subtract(1, 'days').valueOf(),
      createAt: createAt,
    };

    // console.log(
    //   "saveUserLastSearch params",
    //   userId,
    //   departureCity,
    //   arrivalCity,
    //   departureDate,
    //   returnDate,
    //   searchDoc
    // );

    // ["YT72gaWyNNesvvpamwl9", "wtsxzSPXuJDxIz5LxJ8k"].forEach(id =>
    //   db
    //     .collection("users")
    //     .doc(userId)
    //     .collection("lastSearches")
    //     .doc(id)
    //     .delete()
    // );

    return db
      .collection('users')
      .doc(userId)
      .collection('lastSearches')
      .add(searchDoc)
      .then(
        (res) => {
          return res;
        },
        (error) => console.log('error', error)
      );
  };

  saveUserLastSearchLog = (
    userName,
    userId,
    departureCity,
    arrivalCity,
    departureDate,
    returnDate
  ) => {
    const startDateRange = moment(moment.utc(departureDate)).valueOf();

    const dateReturn = moment(moment.utc(returnDate)).valueOf();

    const createAt = moment().valueOf();

    const dCity = {
      id: departureCity.id,
      name: departureCity.name,
      uf: departureCity.uf,
    };

    const aCity = {
      id: arrivalCity.id,
      name: arrivalCity.name,
      uf: arrivalCity.uf,
    };

    const searchDoc = {
      departureCity: dCity,
      arrivalCity: aCity,
      dateDepart: startDateRange,
      dateReturn: dateReturn,
      createAt: createAt,
      userId: userId,
      userName: userName,
      device: 'site',
    };

    return db
      .collection('searches')
      .add(searchDoc)
      .then(
        (res) => {
          return res;
        },
        (error) => console.log('error - LogSearch', error)
      );
  };
}
